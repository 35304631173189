import { Button, Result } from "antd"
import { navigate } from "gatsby"
import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Result
      status="404"
      title="404"
      subTitle="You just hit a route that doesn&#39;t exist... the sadness."
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          Back to Home
        </Button>
      }
    />
  </>
)

export default NotFoundPage
